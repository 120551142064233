import "./App.css";
import { useState } from "react";
import axios from "axios";

function App() {
  const [message, setMessage] = useState(null);

  const submitForm = async (e) => {
    e.preventDefault();
    const res = await axios.post("/api/upload", { data: message });
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      <div>
        <form onSubmit={submitForm}>
          <input
            type="file"
            name="upload"
            onChange={(e) => setMessage(e.target.files[0])}
            value={message}
          />
          <button type="submit">Upload</button>
        </form>
      </div>
    </div>
  );
}

export default App;
